var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-main", { staticClass: "main" }, [
    _c("div", { staticClass: "title" }, [_vm._v("长沙分中心介绍")]),
    _c("div", { staticClass: "sep" }),
    _c("div", { staticClass: "content" }, [
      _vm._v(
        "         国家海外知识产权纠纷应对指导中心长沙分中心系国家知识产权局批复同意设立的 22家地方分中心之一。分中心依托长沙知识产权保护中心建立，重点聚焦长沙市22 条产业链，为湖南省外向型企业在海外遇到的专利、商标、著作权、商业秘密等知识产权纠纷问题，提供高效、专业的知识产权的法律咨询、实务指导、智力支持和资金援助， 构建湖南省海外知识产权纠纷信息收集和发布渠道，解决湖南省企业海外知识产权纠纷应对中的难点和痛点，助力和赋能湖南自贸区发展建设。"
      ),
      _c("br"),
      _vm._v(
        "         分中心主要从海外知识产权纠纷信息收集和发布、应对指导、应对资源协调、风险防控培训与宣传四个方面开展工作。 "
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }