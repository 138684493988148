<template>
  <el-main class="main">
    <div class="title">长沙分中心介绍</div>
    <div class="sep" />
    <div class="content">
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;国家海外知识产权纠纷应对指导中心长沙分中心系国家知识产权局批复同意设立的
      22家地方分中心之一。分中心依托长沙知识产权保护中心建立，重点聚焦长沙市22
      条产业链，为湖南省外向型企业在海外遇到的专利、商标、著作权、商业秘密等知识产权纠纷问题，提供高效、专业的知识产权的法律咨询、实务指导、智力支持和资金援助，
      构建湖南省海外知识产权纠纷信息收集和发布渠道，解决湖南省企业海外知识产权纠纷应对中的难点和痛点，助力和赋能湖南自贸区发展建设。<br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;分中心主要从海外知识产权纠纷信息收集和发布、应对指导、应对资源协调、风险防控培训与宣传四个方面开展工作。
    </div>
  </el-main>
</template>

<script>
import store from "@/store";

export default {
  name: "center-intro",
  beforeRouteEnter(to, from, next) {
    store.commit("service/SET_ACTIVEINDEX", { activeIndex: "1" });
    next();
  },
};
</script>

<style lang="stylus" scoped>
.main {
  height: auto;
  padding-left: 100px;
  padding-right: 100px;

  .title {
    font-size: 30px;
    font-family: PingFang-SC-Heavy, PingFang-SC;
    font-weight: 800;
    color: #2C53B0;
    line-height: 42px;
    text-align: center;
  }

  .sep {
    height: 2px;
    background: #2C53B0;
    margin-top: 14px;
  }

  .content {
    font-size: 16px;
    font-family: PingFang-SC;
    font-weight: 400;
    color: #222222;
    line-height: 28px;
    margin-top: 22px;
  }
}
</style>